/*
 * Copyright 2025 Tridium Inc. All rights reserved.
 */
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import {
  NiagaraButton2,
  NiagaraCheckbox,
  NiagaraIcon,
  NiagaraModal,
} from '@Niagara-Cloud-Suite/Niagara-Cloud-Suite.NiagaraManagementPlaneCommons-lib';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
  DraggableProvided,
  DraggableStateSnapshot,
} from '@hello-pangea/dnd';
import { Modal } from '@scuf/common';
import './ShowColumns.scss';
import _ from 'lodash';

export interface ShowColumnsItemProps {
  label: string;
  field: string;
  checked: boolean;
  disabled: boolean;
}

interface ShowColumnsDraggableItemProps {
  provided: DraggableProvided;
  dragSnapshot: DraggableStateSnapshot;
}

interface ShowColumnsModalProps<T extends ShowColumnsItemProps> {
  header: ReactNode;
  currentItems: T[];
  defaultItems: T[];
  onCancel: () => void;
  onSave: (_: T[]) => void;
}

export function ShowColumnsModal<T extends ShowColumnsItemProps>({
  header,
  currentItems,
  defaultItems,
  onCancel,
  onSave,
}: ShowColumnsModalProps<T>) {
  const [items, updateItems] = useState(currentItems);
  useEffect(() => updateItems(currentItems), [currentItems]);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const handleDrop = (droppedItem: DropResult) => {
    if (!droppedItem.destination) return;
    updateItems((items) => {
      const updatedList = [...items];
      const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
      droppedItem.destination && updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
      return updatedList;
    });
  };

  useEffect(() => {
    const checkedColumnCount = items?.filter((i) => i.checked)?.length;
    const hasDisabledColumn = items.find((i) => i.disabled);
    if (checkedColumnCount === 2 && !hasDisabledColumn) {
      updateItems((columns) => columns.map((column) => ({ ...column, disabled: !column.checked })));
    } else if (checkedColumnCount < 2 && hasDisabledColumn) {
      updateItems((columns) => columns.map((column) => ({ ...column, disabled: false })));
    }
  }, [items]);

  return (
    <NiagaraModal className='show-columns-modal' open={true} size='mini'>
      <div ref={wrapperRef}>
        <Modal.Header>
          <div className='modal-heading'>{header}</div>
          <NiagaraButton2 type='secondary-link' icon='Close' iconColor='#606060' onClick={onCancel} />
        </Modal.Header>
        <div className='seperator' />
        <Modal.Content>
          <div className='modal-content'>
            <NiagaraButton2
              className='default-items'
              type='primary-link'
              content='Reset to default'
              icon='Redo'
              iconPosition='right'
              onClick={() => {
                updateItems(defaultItems);
              }}
            />
            <div className='column-info'>Only 2 columns can be selected</div>
            <div className='item-selectors'>
              <DragDropContext onDragEnd={handleDrop}>
                <Droppable droppableId='list-container'>
                  {(provided) => (
                    <div className='list-container' {...provided.droppableProps} ref={provided.innerRef}>
                      {items.map((columnProps, index) => (
                        <Draggable key={columnProps.label} draggableId={columnProps.label} index={index}>
                          {(provided, dragSnapshot) => {
                            if (dragSnapshot.isDragging && provided.draggableProps.style) {
                              provided.draggableProps.style.top =
                                provided.draggableProps.style.top -
                                (document
                                  .getElementsByClassName('show-columns-modal')
                                  .item(0)
                                  ?.getBoundingClientRect().top ?? 0);
                              const transform = provided.draggableProps.style.transform;
                              if (transform?.includes(',')) {
                                const t = transform.split(',')[1];
                                provided.draggableProps.style.transform = 'translate(0px,' + t;
                              }
                            }
                            return (
                              <ShowColumnsItem
                                key={columnProps.label}
                                {...columnProps}
                                provided={provided}
                                dragSnapshot={dragSnapshot}
                                onChange={() =>
                                  updateItems((columns) =>
                                    columns.map((column) =>
                                      column.label === columnProps.label
                                        ? { ...column, checked: !column.checked || column.disabled }
                                        : column
                                    )
                                  )
                                }
                              />
                            );
                          }}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              {!items ? <div>No columns found</div> : null}
            </div>
          </div>
        </Modal.Content>
        <div className='seperator' />
        <Modal.Footer>
          <div className='button-container'>
            <NiagaraButton2 type='secondary' content='Cancel' onClick={onCancel} />
            <NiagaraButton2
              type='primary'
              content='Save'
              onClick={() => onSave(items)}
              disabled={items.filter((i) => i.checked).length !== 2 || _.isEqual(items, defaultItems)}
            />
          </div>
        </Modal.Footer>
      </div>
    </NiagaraModal>
  );
}

export function ShowColumnsItem({
  label,
  checked,
  disabled,
  onChange,
  provided,
  dragSnapshot: { isDragging },
}: ShowColumnsDraggableItemProps & Parameters<typeof NiagaraCheckbox>[0] & { label: string }) {
  return (
    <div
      className={`item-selector ${isDragging ? 'dragged' : ''}`}
      ref={provided.innerRef}
      {...provided.dragHandleProps}
      {...provided.draggableProps}
    >
      <NiagaraCheckbox {...{ label, checked, disabled, onChange }} />
      <NiagaraIcon name='Grabber' color={isDragging ? '#005eac' : '#707070'} />
    </div>
  );
}
