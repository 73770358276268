/*
 * Copyright 2025 Tridium Inc. All rights reserved.
 */
import {
  NiagaraButton2,
  NiagaraModal,
  useSnackBar,
} from '@Niagara-Cloud-Suite/Niagara-Cloud-Suite.NiagaraManagementPlaneCommons-lib';
import { Modal } from '@scuf/common';
import React from 'react';
import ProjectList from '../ProjectList';
import '../../components/CollapsibleSideBar/CollapsibleSidebar.scss';
interface IProjectModal {
  onClose: () => void;
}

export function ProjectListModal({ onClose }: IProjectModal) {
  const { SnackBar, showSnackBar } = useSnackBar();
  return (
    <NiagaraModal className='projects-list-modal project-list' open={true} size='small'>
      <Modal.Header>
        <NiagaraButton2 type='secondary-link' icon='Close' iconColor='#606060' onClick={onClose} />
      </Modal.Header>
      <Modal.Content>
        <ProjectList showSnackBar={showSnackBar} />
      </Modal.Content>
    </NiagaraModal>
  );
}
