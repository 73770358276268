/*
 * Copyright 2024 Tridium Inc. All rights reserved.
 */
import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  ReactNode,
} from 'react';
import { ResponsiveHelper } from '@scuf/common';
import { IActiveBreakpoints } from '@scuf/common/dist/components/ResponsiveHelper/IResponsiveHelperProps';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface IResponsiveState {
  width: number;
  breakpoints: { name: string; width: number; index: number };
  activeBreakpoint: IActiveBreakpoints;
  responsiveDevice: string;
}

const ResponsiveContext = createContext<IResponsiveState | undefined>(
  undefined
);

interface ResponsiveProviderProps {
  children: ReactNode;
}

export function ResponsiveProvider({ children }: ResponsiveProviderProps) {
   const { enableMobileResponsive } = useFlags();

  const [responsive, setResponsive] = useState<IResponsiveState>({
    width: 0,
    breakpoints: { index: 4, name: 'media-xl', width: 1600 },
    activeBreakpoint: {
      xs: false,
      s: false,
      m: false,
      l: true,
      xl: true,
    },
    responsiveDevice: 'Desktop',
  });
  const getCategoryByIndex = (index: number): string => {
    switch (index) {
      case 0:
        return 'Mobile';
      case 1:
        return 'Tablet';
      case 2:
        return 'Tablet Pro';
      case 3:
        return 'Desktop';
      default:
        return 'Desktop';
    }
  };

  const handleResize = (width: number) => {
    setResponsive((prev) => ({ ...prev, width }));
  };

  const handleBreakpointChange = (breakpoints: {
    name: string;
    width: number;
    index: number;
  }) => {
    if (enableMobileResponsive) {
      setResponsive((prev) => ({
        ...prev,
        breakpoints,
        responsiveDevice: getCategoryByIndex(breakpoints.index),
      }));
    }
  };

  const handleActiveBreakpointChange = (
    activeBreakpoint: IActiveBreakpoints
  ) => {
    setResponsive((prev) => ({ ...prev, activeBreakpoint }));
  };

  useEffect(() => {
    handleResize(responsive.width);

    handleBreakpointChange(responsive.breakpoints);

    handleActiveBreakpointChange(responsive.activeBreakpoint);
  }, []);

  return (
    <ResponsiveContext.Provider value={responsive}>
      <ResponsiveHelper
        handleHeight={true}
        onChange={(width) => handleResize(width)}
        activeBreakPoints={(points) => handleActiveBreakpointChange(points)}
        onBreakpoint={(points) => handleBreakpointChange(points)}
      />
      {children}
    </ResponsiveContext.Provider>
  );
}

export function useResponsive(): IResponsiveState {
  const context = useContext(ResponsiveContext);
  if (context === undefined) {
    throw new Error('useResponsive must be used within a ResponsiveProvider');
  }
  return context;
}
