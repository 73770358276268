/*
 * Copyright 2025 Tridium Inc. All rights reserved.
 */
import { niagaraHttp } from '../../utils/niagaraHttp';
export interface NotificationSettings {
  eventTypeId: number;
  notificationType: string;
  subscribe: boolean;
}
export interface IAllNotifications {
  content: [];
  totalPages: number;
  size: number;
  numberOfElements: number;
}

export async function getNotificationSettingsAsync(userId: number) {
  return niagaraHttp.get<NotificationSettings[]>(`${window.ENV.API_BASE_URL}/api/v1/notifications/users/${userId}`);
}

export async function saveNotificationSettingsAsync(userId: number, payload: NotificationSettings[]) {
  return niagaraHttp.put<NotificationSettings[]>(
    `${window.ENV.API_BASE_URL}/api/v1/notifications/users/${userId}`,
    payload
  );
}

export async function getAllNotificationAsync(userId: string) {
  return niagaraHttp.get<IAllNotifications>(`${window.ENV.API_BASE_URL}/api/v1/notifications/users/${userId}`);
}
