/*
 * Copyright 2025 Tridium Inc. All rights reserved.
 */
import moment from 'moment';
export const notificationsMockData = {
  content: [
    {
      subject: 'Subscription Renewed',
      generatedTime: moment(new Date()).format('DD/MM/YYYY hh:mm A'),
      content: 'has been renewed',
      expireInDays: 30,
      isUnread: true,
      isSuccess: true,
    },
    {
      subject: 'Subscription Expires In 07 Days',
      generatedTime: moment(new Date(new Date().setDate(new Date().getDate() - 7))).format('DD/MM/YYYY hh:mm A'),
      content: 'will expire on ' + moment(new Date(new Date().setDate(new Date().getDate() + 7))).format('DD/MM/YYYY'),
      expireInDays: 7,
      isUnread: true,
      isSuccess: false,
    },

    {
      subject: 'Subscription Expires In 15 Days',
      generatedTime: moment(new Date(new Date().setDate(new Date().getDate() - 3))).format('DD/MM/YYYY hh:mm A'),
      content: 'will expire on ' + moment(new Date(new Date().setDate(new Date().getDate() + 15))).format('DD/MM/YYYY'),
      expireInDays: 15,
      isUnread: false,
      isSuccess: false,
    },
    {
      subject: 'Subscription Expires In 30 Days',
      generatedTime: moment(new Date(new Date().setDate(new Date().getDate() - 6))).format('DD/MM/YYYY hh:mm A'),
      content: 'will expire on ' + moment(new Date(new Date().setDate(new Date().getDate() + 30))).format('DD/MM/YYYY'),
      expireInDays: 30,
      isUnread: true,
      isSuccess: false,
    },
    {
      subject: 'Subscription Expires In 30 Days',
      generatedTime: moment(new Date(new Date().setDate(new Date().getDate() - 1))).format('DD/MM/YYYY hh:mm A'),
      content: 'will expire on ' + moment(new Date(new Date().setDate(new Date().getDate() + 30))).format('DD/MM/YYYY'),
      expireInDays: 30,
      isUnread: false,
      isSuccess: false,
    },
    {
      subject: 'Subscription Expires In 30 Days',
      generatedTime: moment(new Date(new Date().setDate(new Date().getDate() - 3))).format('DD/MM/YYYY hh:mm A'),
      content: 'will expire on ' + moment(new Date(new Date().setDate(new Date().getDate() + 30))).format('DD/MM/YYYY'),
      expireInDays: 30,
      isUnread: false,
      isSuccess: false,
    },
  ],
  totalPages: 0,
  size: 0,
  numberOfElements: 6,
};
export const notificationSettingsMockData = [
  {
    eventTypeId: 1,
    notificationType: 'email',
    subscribe: true,
  },
  {
    eventTypeId: 2,
    notificationType: 'email',
    subscribe: false,
  },
];
