/*
 * Copyright 2025 Tridium Inc. All rights reserved.
 */
import React, { useState } from 'react';
import { Divider } from '@scuf/common';
import './NotificationComponent.scss';
import {
  NiagaraButton2,
  NiagaraIcon,
} from '@Niagara-Cloud-Suite/Niagara-Cloud-Suite.NiagaraManagementPlaneCommons-lib';
import { NotificationItem } from './NotificationItem';
import { NoNotificationComponent } from './NoNotificationComponent';
import Status from '../../../utils/Status';
// import { useUserRoleStore } from '../../../userroles/UseUserRoleStore';
import { ApiStatus } from '../../../utils/Types';
// import { getAllNotificationAsync } from '../../../api/notifications';
import { notificationsMockData } from './mockData';
import { ApiError } from '../../../utils/niagaraHttp';

interface NotificationComponentProps {
  onCloseNotification: (dataClose?: string) => void;
}

export function NotificationComponent({ onCloseNotification }: NotificationComponentProps) {
  const [status] = useState<ApiStatus>('fulfilled');
  const [error] = useState<ApiError>();
  const [notifications] = useState<
    {
      subject: string;
      generatedTime: string;
      content: string;
      expireInDays: number;
      isUnread: boolean;
      isSuccess: boolean;
    }[]
  >(notificationsMockData.content);

  //TODO Will implement when api will available
  // const {
  //   currentUser: { userId },
  // } = useUserRoleStore();

  // useEffect(() => {
  //   setStatus('pending');
  //   setError(undefined);
  //   if (!userId) return;
  //    getAllNotificationAsync(userId)
  //     .then(() => {
  //       setStatus('fulfilled');
  //     })
  //     .catch((e: ApiError) => {
  //       setStatus('rejected');
  //       setNotifications([]);
  //       setError(e);
  //     });
  // }, []);

  return (
    <div className='notification-model-container'>
      <div className='notification-header'>
        <span className='setting-icon'>
          <NiagaraButton2
            type='secondary-link'
            icon='Settings'
            iconSize={16}
            iconColor='#0071B3'
            onClick={() => onCloseNotification('ConfigureNotification')}
          />
        </span>
        <span className='title'>Notifications</span>
        <span className='close-icon'>
          <NiagaraButton2
            type='secondary-link'
            icon='Close'
            iconColor='#606060'
            onClick={() => onCloseNotification()}
          />
        </span>
      </div>
      <Divider fitted={true} />
      <div className='notification-header'>
        <div className='notification-read'>
          <button className='all-btn'>All ({notifications.length})</button>
          <button className='unread-btn'>Unread (0)</button>
        </div>
        <div className='MarkRead'>
          <NiagaraIcon name='MarkAllRead' size={16} /> Mark all as read
        </div>
      </div>
      <Divider fitted={true} />
      <Status status={status} error={error}>
        <div className='card-content' key='notification-list'>
          {notifications.length > 0 ? (
            notifications.map((n, i) => (
              <>
                <NotificationItem key={`notification-item${i}`} {...n} />
                {i !== notifications.length - 1 && <Divider fitted={true} key={`notification-divider${i}`} />}
              </>
            ))
          ) : (
            <NoNotificationComponent title='No notifications' />
          )}
        </div>
      </Status>
    </div>
  );
}
